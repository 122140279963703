import styles from "./BalanceCard.module.css";
import React from "react";

const BalanceCard = ({amount}) => {
    return (
        <section className={styles.balanceCard}>
            <span className={styles.accountTitle}>Баланс</span>
            <span className={styles.balance}>{amount ? amount : 0} ₽</span>
            <div className={styles.actionGroup}>
                <button className={styles.historyButton}>История платежей</button>
                <div className={styles.rechargeGroup}>
                    <button className={styles.recharge}>Пополнить</button>
                    <img
                        className={styles.rechargeIcon}
                        src={`/img/common/icons/arrow-black-right.svg`}
                        alt="Пополнить"
                    />
                </div>
            </div>
        </section>
    );
};

export default BalanceCard;