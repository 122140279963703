import React from "react";
import styles from "./ServiceList.module.css";

const ServiceList = ({services, onClick}) => {

    const [selectedService, setSelectedService] = React.useState(null);

    const handleClick = (serviceId) => {
        if (onClick) {
            onClick(serviceId);
            setSelectedService(serviceId);
        }
    };

    return (
        <div className={styles.listContainer}>
            {services?.map((service, index) => (
                <div
                    key={index}
                    className={`${styles.serviceContainer} ${selectedService === service?.id ? styles.selected : ''}`}
                    onClick={() => handleClick(service?.id)}>
                    <div className={styles.titleContainer}>
                        <span className={styles.title}>{service?.name}</span>
                        <span className={styles.price}>{service?.price} ₽</span>
                    </div>
                    <span className={styles.duration}>{service?.duration} мин.</span>
                </div>
            ))}
        </div>
    );
};

export default ServiceList;