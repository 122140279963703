import {retryableApiClient, simpleApiClient} from "./apiClient";

export const registration = async (userType, questionary) => {
    if (questionary.sex === '') {
        questionary.sex = null;
    }
    if (questionary.photoBase64 === '') {
        questionary.photoBase64 = null;
    }

    return simpleApiClient.post(
        `/v1/user/registration?userType=${userType}`,
        {
            telegramId: questionary.telegramId,
            photoBase64: questionary.photoBase64,
            firstName: questionary.firstName,
            lastName: questionary.lastName,
            sex: questionary.sex,
            birthday: questionary.birthday,
        }
    );
};

export const updatePhoto = async (photoBase64) => {
    if (photoBase64 == null || photoBase64 === '') {
        console.log('Не выбрано изображение');
        return;
    }

    return retryableApiClient.put(`/v1/user/photo`, {photoBase64: photoBase64});
};


export const findCalendarEvents = async (year, month) => {
    let url = `/v1/user/calendar`;
    return retryableApiClient.get(`${url}?year=${year}&month=${month}`);
};

export const findUpcomingTraining = async () => {
    let url = `/v1/user/trainings/upcoming`;
    return retryableApiClient.get(url);
};

export const findArchiveTrainings = async (pageNumber, pageSize) => {
    let url = `/v1/user/trainings/archive`;
    if (pageNumber && pageSize) {
        url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return retryableApiClient.get(url);
};