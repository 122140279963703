import React, {useEffect, useRef, useState} from "react";
import styles from "./ProfilePicture.module.css";


const ProfilePicture = ({src, alt, onChange}) => {

    const fileInputRef = useRef(null);

    const [pictureSrc, setPictureSrc] = useState(src);

    useEffect(() => {
        setPictureSrc(src);
    }, [src]);

    const handleButtonClick = () => {
        // Программно кликаем на input
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (onChange != null) {
                onChange(file);
            }
            setPictureSrc(URL.createObjectURL(file));
        }
    };

    return (
        <div>
            <img
                className={styles.profilePicture}
                src={pictureSrc}
                alt={alt ? alt : "ProfilePicture"}
                onClick={onChange ? handleButtonClick : null}
            />
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{display: 'none'}}
                onChange={handleFileChange}
            />
        </div>
    )
}

export default ProfilePicture;