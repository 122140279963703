import React, {useEffect, useState} from "react";
import styles from "./Calendar.module.css";
import {format} from "date-fns";
import {PATTERN_DATE} from "../../utils/DateUtil";

const Calendar = ({eventDates = [], selectedDate, onChangeHighlightedDate, onChangeMonth}) => {

    const [visibleMonth, setVisibleMonth] = useState(new Date());
    const [highlightedDate, setHighlightedDate] = useState(null);

    useEffect(() => {
        setHighlightedDate(selectedDate);
    }, [selectedDate]);

    function getCalendarMonth(date) {
        const year = date.getFullYear();
        const month = date.getMonth();

        const daysInMonth = new Date(year, month + 1, 0, 12).getDate();
        const dayOfWeekStart = (new Date(year, month).getDay() - 1 + 7) % 7; // -1 потому что понедельник должен быть 0
        const calendar = Array(dayOfWeekStart).fill(null); // Заполняем дни до 1-го числа месяца

        // Добавляем дни месяца в календарь
        for (let day = 1; day <= daysInMonth; day++) {
            calendar.push(new Date(year, month, day));
        }

        const dayOfWeekEnd = (new Date(year, month + 1, 0).getDay() - 1 + 7) % 7; // Получаем день недели последнего дня месяца

        // Если последний день не воскресенье, то добавляем дни после последнего дня месяца
        if (dayOfWeekEnd !== 6) {
            const afterDays = Array(6 - dayOfWeekEnd).fill(null);
            calendar.push(...afterDays);
        }

        return calendar;
    }

    function getCalendarArray() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return getCalendarMonth(visibleMonth)
            .map((date, index) => {
                if (!date) {
                    return <div key={index} className={styles.day}></div>;
                }

                const isHighlighted = highlightedDate && date.getTime() === highlightedDate.getTime();
                const isExistEventDate = eventDates.includes(format(date, PATTERN_DATE));

                let color = 'black';
                let backgroundColor = 'white';

                if (isHighlighted) {
                    color = 'white';
                    backgroundColor = 'blue';
                } else if (date.getTime() < today.getTime()) {
                    if (isExistEventDate) {
                        color = 'black';
                        backgroundColor = 'grey';
                    }
                } else if (date.getTime() === today.getTime()) {
                    color = 'blue';
                } else {
                    if (isExistEventDate) {
                        color = 'white';
                        backgroundColor = 'black';
                    }
                }

                return (
                    <div
                        key={index}
                        className={styles.day}
                        style={{color, backgroundColor: backgroundColor}}
                        onClick={() => onClickHighlightedDateHandler(date)}
                    >
                        {date.getDate()}
                    </div>
                );
            });
    }

    function getMonthName() {
        let monthName = visibleMonth.toLocaleString('ru-RU', {month: 'long'});
        return monthName.charAt(0).toUpperCase() + monthName.slice(1);
    }

    function minusMonth() {
        const newDate = new Date(visibleMonth.getFullYear(), visibleMonth.getMonth() - 1, visibleMonth.getDate());
        setVisibleMonth(newDate);
        if (onChangeMonth) {
            onChangeMonth(newDate);
        }
    }

    function plusMonth() {
        const newDate = new Date(visibleMonth.getFullYear(), visibleMonth.getMonth() + 1, visibleMonth.getDate());
        setVisibleMonth(newDate);
        if (onChangeMonth) {
            onChangeMonth(newDate);
        }
    }

    function onClickHighlightedDateHandler(date) {
        if (!eventDates) {
            return;
        }

        if (eventDates.includes(format(date, PATTERN_DATE))) {
            setHighlightedDate(date);
            if (onChangeHighlightedDate) {
                onChangeHighlightedDate(date);
            }
        }
    }

    return (
        <div className={styles.container}>
            {/*<header className={styles.header}></header>*/}
            <main className={styles.main}>
                <div className={styles.monthNavigation}>
                    <h2 className={styles.monthTitle}>{getMonthName()}</h2>
                    <div className={styles.navigationButtons}>
                        <button className={styles.navButton} onClick={minusMonth}>
                            <img
                                className={styles.navButtonImg}
                                src={`/img/common/icons/arrow-black-left.svg`}
                                alt="Previous Month"
                            />
                        </button>
                        <button className={styles.navButton} onClick={plusMonth}>
                            <img
                                className={styles.navButtonImg}
                                src={`/img/common/icons/arrow-black-right.svg`}
                                alt="Next Month"
                            />
                        </button>
                    </div>
                </div>
                <div className={styles.weekDays}>
                    {["пн", "вт", "ср", "чт", "пт", "сб", "вс"].map((day) => (
                        <div key={day} className={styles.weekDay}>{day}</div>
                    ))}
                </div>
                <div className={styles.daysGrid}>
                    {getCalendarArray()}
                </div>
            </main>
        </div>
    );
};

export default Calendar;