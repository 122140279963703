import React from "react";
import WideButton from "../common/WideButton";
import styles from "./ChooseClientType.module.css"
import {useNavigate} from "react-router-dom";
import pages from '../../pages';

const ChooseClientType = () => {

    let navigate = useNavigate();

    const goToRegistration = (clientType) => {
        navigate(`${pages.registration}?category=${clientType}`);
    }

    return (
        <div className={styles.chooseClientContainer}
             style={{backgroundImage: `url(img/registration/2_registration.png)`}}>
            <div className={styles.chooseButtons}>
                <WideButton text="Я ищу тренера" onClick={() => goToRegistration('CLIENT')}/>
                <WideButton text="Я тренер" onClick={() => goToRegistration('TRAINER')}/>
            </div>
        </div>
    );
}

export default ChooseClientType;

