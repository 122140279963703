

export const convertToBase64 = (file, onLoadEnd) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
        if (onLoadEnd) {
            const base64String = reader.result.split(',')[1]; // Удаляем метаданные
            onLoadEnd(base64String);
        }
    };
    reader.onerror = (error) => {
        console.error('Ошибка при конвертации файла в Base64:', error);
    };
};