import axios from 'axios';
import * as authService from "./authService";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Создаем экземпляр axios (опционально)
const simpleApiClient = axios.create({
    baseURL: backendUrl,
    headers: {'Content-Type': 'application/json'}
});

// Создаем экземпляр axios (опционально)
const retryableApiClient = axios.create({
    baseURL: backendUrl,
    // timeout: 1000,
    headers: {'Content-Type': 'application/json'}
});

// Добавляем перехватчик для запросов
retryableApiClient.interceptors.request.use(
    async (config) => {
        // Получаем токен из localStorage
        const token = localStorage.getItem('authToken');
        if (token) {
            // Добавляем Bearer токен в заголовки
            config.headers.Authorization = `Bearer ${token}`;
        }

        // Добавляем ключ идемпотентности в заголовки
        config.headers['Idempotency-Key'] = crypto.randomUUID();

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// Добавляем перехватчик для ответов
retryableApiClient.interceptors.response.use(
    (response) => {
        // Если ответ успешный, просто возвращаем его
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Проверяем статус ошибки
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            await authService.login();
            const newToken = localStorage.getItem('authToken');

            if (newToken) {
                // Обновляем заголовок авторизации и повторяем запрос
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return retryableApiClient(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

export {simpleApiClient, retryableApiClient};