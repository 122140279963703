import React from 'react';
import styles from "./ScrollList.module.css";


const ScrollList = ({ items }) => {
    return (
        <div className={styles.scrollContainer}>
            <div className={styles.scrollContent}>
                {items.map((item, index) => (
                    <div key={index} className={styles.scrollItem}>
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ScrollList;