import React, {useEffect} from 'react';
import styles from './NavigationBar.module.css';
import {useLocation, useNavigate} from 'react-router-dom';
import pages from '../../pages'

const NavigationBar = () => {

    const location = useLocation();
    let navigate = useNavigate();

    const [navigationItems, setNavigationItems] = React.useState([
        {
            code: 'calendar',
            text: 'Расписание',
            link: pages.schedule
        },
        {
            code: 'home',
            text: 'Главная',
            link: pages.home
        },
        {
            code: 'profile',
            text: 'Профиль',
            link: pages.profile
        },
    ]);

    const iconsSrc = '/img/common/navbar/';

    const getIconSrc = (code, isActive) => {
        let targetName;
        if (isActive) {
            targetName = `${iconsSrc + code}_active.svg`;
        } else {
            targetName = `${iconsSrc + code}.svg`;
        }
        return targetName;
    }

    useEffect(() => {
        let actualNavigationItems = [...navigationItems];

        actualNavigationItems.forEach((item) => {
            if (location.pathname.startsWith(item.link)) {
                item.iconSrc = getIconSrc(item.code, true);
            } else {
                item.iconSrc = getIconSrc(item.code, false);
            }
        });

        setNavigationItems(actualNavigationItems);
    }, [location]);

    return (
        <nav className={styles.Nav}>
            {navigationItems?.map((item, index) => (
                <div className={styles.NavItem} key={index}  onClick={() => navigate(item?.link)}>
                    <img className={styles.NavIcon}
                        src={item?.iconSrc}
                        alt={item?.code}
                        loading="lazy" />
                    <div className={styles.NavText}>{item?.text}</div>
                </div>
            ))}
        </nav>
    );
};

export default NavigationBar;