import React, {useEffect, useState} from "react";
import styles from "./TrainerPage.module.css";
import ServiceList from "./ServiceList";
import Calendar from "./Calendar";
import ReviewContainer from "./ReviewContainer";
import {useLocation, useNavigate} from "react-router-dom";
import {
    bookingTrainerService,
    findTrainerById,
    findTrainerFreeDates,
    findTrainerService,
    toggleFavoriteTrainers
} from '../../services/clientService';
import NavigationBar from "../common/NavigationBar";
import WideButton from "../common/WideButton";
import {handleRestError} from "../../services/restUtils";
import {format} from "date-fns";
import {PATTERN_DATE} from "../../utils/DateUtil";

const TrainerPage = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [trainerId, setTrainerId] = useState(null);
    const [trainerProfile, setTrainerProfile] = useState(null);
    const [trainerServices, setTrainerServices] = useState(null);
    const [isFavorite, setIsFavorite] = useState(null);

    const [trainerFreeDates, setTrainerFreeDates] = useState([]);

    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const [availableTimes, setAvailableTimes] = useState([]);

    useEffect(() => {
        setAvailableTimes(getAvailableTimes());
    }, [selectedDate]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        let id = query.get("id");

        if (id) {
            setTrainerId(id);
            findTrainerById(id)
                .then(response => {
                    const trainer = response.data;
                    setTrainerProfile(trainer);
                    setIsFavorite(trainer?.isFavorite);
                })
                .catch(error => handleRestError(error, navigate));

            findTrainerService(id)
                .then(response => {
                    setTrainerServices(response.data);
                })
                .catch(error => handleRestError(error, navigate));
        }

    }, [location, navigate]);

    useEffect(() => {
        refreshTrainerFreeDates(trainerId);
    }, [trainerId]);

    function refreshTrainerFreeDates(trainerId) {
        if (!trainerId) {
            return;
        }
        findTrainerFreeDates(trainerId)
            .then(response => {
                const currentDate = new Date().setHours(0, 0, 0, 0);

                const dates = response.data?.filter(freeDate => {
                    const freeDateObj = new Date(freeDate?.date).setHours(0, 0, 0, 0);
                    return freeDateObj >= currentDate;
                })
                setTrainerFreeDates(dates);
            })
            .catch(error => handleRestError(error, navigate));
    }

    function getCoachPhoto(coach) {
        if (coach != null && coach.image != null) {
            return `data:image/jpeg;base64,${coach.image}`;
        } else {
            return coach?.sex === 'M' ? `/img/home/trainer/man-image.png` : '/img/home/trainer/girl-image.png';
        }
    }

    function handleReservationButton() {
        if (canReserve(selectedDate, selectedTime)) {
            bookingTrainerService(trainerId, selectedServiceId, format(selectedDate, PATTERN_DATE), selectedTime)
                .then(() => {
                    // navigate(-1);
                    console.log("Успешно забронировали тренировку");
                    handleDateChange(null);
                    refreshTrainerFreeDates(trainerId);
                })
                .catch(error => handleRestError(error, navigate));
        }
    }

    function getAvailableTimes() {
        if (!trainerFreeDates || !selectedDate) {
            return [];
        }

        const serviceDuration = trainerServices?.find(service => service.id === selectedServiceId)?.duration;
        const currentDate = new Date();

        // Из полученных свободных диапазонов алгоритм извлекает более мелкие диапазоны времени,
        // которые по длительности совпадают с выбранной услугой
        return trainerFreeDates
            .filter(freeDate => {
                return freeDate?.date === format(selectedDate, PATTERN_DATE);
            })
            .flatMap(freeDate => {
                const times = [];
                freeDate?.freeTime?.forEach(freeTime => {
                    let [startHour, startMinute] = freeTime.start.split(':').map(Number);
                    let [endHour, endMinute] = freeTime.end.split(':').map(Number);

                    let startTime = new Date(0, 0, 0, startHour, startMinute);
                    const endTime = new Date(0, 0, 0, endHour, endMinute);


                    const millsInMinute = 60000;
                    while (startTime.getTime() + serviceDuration * millsInMinute <= endTime.getTime()) {
                        let newEndTime = new Date(startTime.getTime() + serviceDuration * millsInMinute);

                        // If the free date is today, skip times that start before the current time
                        if (!(freeDate?.date === format(currentDate, PATTERN_DATE) && startTime < currentDate)) {
                            times.push({
                                start: startTime.toTimeString().slice(0, 5),
                                end: newEndTime.toTimeString().slice(0, 5)
                            });
                        }
                        startTime = newEndTime;
                    }
                });
                return times;
            });
    }

    function canReserve(date, time) {
        if (!date || !time) {
            return false;
        }
        date = date.setHours(0, 0, 0, 0);
        const currentDate = new Date().setHours(0, 0, 0, 0);

        return date >= currentDate;
    }

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
        setAvailableTimes(getAvailableTimes());
        setSelectedTime(null);  // при изменении даты выбранное время сбрасывается
    };

    const handleChangeMonth = (newDate) => {
        handleDateChange(null); // при изменении месяца выбранная дата сбрасывается
    };

    function toggleFavoriteTrainer() {
        toggleFavoriteTrainers(trainerId, !isFavorite)
            .then(() => {
                setIsFavorite(!isFavorite);
            })
            .catch(error => handleRestError(error, navigate));
    }

    const onClickServiceHandler = (serviceId) => {
        setSelectedServiceId(serviceId);
        handleDateChange(null);
    };

    return (
        <div className={styles.container}>
            <div className={styles.trainerPhotoContainer}
                 style={{backgroundImage: `url(${getCoachPhoto(trainerProfile)})`}}>
                <div className={styles.panelRow}>
                    <img
                        className={styles.panelIcon}
                        loading="lazy"
                        src={`/img/common/icons/arrow-white-left.svg`}
                        alt="Left icon"
                        onClick={() => navigate(-1)}
                    />
                    <img
                        onClick={toggleFavoriteTrainer}
                        className={styles.panelIcon}
                        loading="lazy"
                        src={isFavorite ? `/img/trainer/heart-like.svg` : `/img/trainer/heart-unlike.svg`}
                        alt="Is trainer favorite"
                    />
                </div>
                <div className={styles.trainerNameContainer}>
                    <span className={styles.trainerName}>{trainerProfile?.fullName}</span>
                    <div className={styles.ratingContainer}>
                        <span className={styles.rating}>{trainerProfile?.rating}</span>
                        <img
                            className={styles.ratingIcon}
                            loading="lazy"
                            src={`/img/common/icons/star-white.svg`}
                            alt="Star rating"
                        />
                    </div>
                </div>
            </div>

            <div className={styles.optionalBlocks}>
                {trainerProfile?.skills ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Дисциплины</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <section className={styles.skillTagContainer}>
                                {trainerProfile?.skills?.map((tag) => (
                                    <span className={styles.skillTag}>{tag}</span>
                                ))}
                            </section>
                        </section>
                    </div>
                    : null
                }

                {trainerProfile?.about ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>О себе</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <span className={styles.trainerAbout}>
                                {trainerProfile?.about}
                            </span>
                        </section>
                    </div>
                    : null
                }

                {trainerProfile?.address ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Адрес</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <address className={styles.Address}>
                                {trainerProfile?.address}
                            </address>
                        </section>
                    </div>
                    : null
                }

                {trainerServices ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Услуги</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <ServiceList services={trainerServices} onClick={onClickServiceHandler}/>
                        </section>
                    </div>
                    : null
                }

                {selectedServiceId ?
                    <div className={styles.bookingContainer}>
                        <Calendar eventDates={trainerFreeDates?.map(freeDate => format(freeDate?.date, PATTERN_DATE))}
                                  onChangeHighlightedDate={handleDateChange}
                                  onChangeMonth={handleChangeMonth}
                                  selectedDate={selectedDate}
                        />
                        {selectedDate ?
                            <section className={styles.timeRangeContainer}>
                                {availableTimes?.map((freeTime) => (
                                    <span
                                        className={`${styles.timeRangeTag} ${freeTime === selectedTime ? styles.timeRangeTagSelected : ''}`}
                                        onClick={() => setSelectedTime(freeTime)}
                                    >{freeTime.start} - {freeTime.end}</span>
                                ))}
                            </section>
                            : null
                        }
                        {selectedTime ?
                            <div className={styles.bookingButton}>
                                <WideButton text="Забронировать"
                                            transparent={!canReserve(selectedDate, selectedTime)}
                                            onClick={handleReservationButton}
                                />
                            </div>
                            : null
                        }
                    </div>
                    : null
                }

                {trainerId ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Отзывы</h2>
                            <button className={styles.viewAllButton}>Смотреть все</button>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <ReviewContainer trainerId={trainerId}/>
                        </section>
                    </div>
                    : null
                }

            </div>
            <NavigationBar/>
        </div>
    );
};

export default TrainerPage;