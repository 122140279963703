import React, {useEffect, useState} from 'react';
import styles from "./WorkTimeModal.module.css";

import {getFullWeekdayName} from "../../../utils/DateUtil";

const WorkTimeModal = ({dayOfWeek, timeRanges, isOpen, onClose, onSave}) => {

    const [ranges, setRanges] = useState(timeRanges);

    useEffect(() => {
        setRanges(timeRanges);
    }, [timeRanges]);

    const handleAddTimeRange = () => {
        if (ranges != null) {
            setRanges([...ranges, {start: '', end: ''}]);
        } else {
            setRanges([{start: '', end: ''}]);
        }
    };

    const handleTimeChange = (index, field, value) => {
        const newTimeRanges = ranges.map((range, i) =>
            i === index ? {...range, [field]: value} : range
        );
        setRanges(newTimeRanges);
    };

    const handleSaveChanges = () => {
        if (onSave) {
            onSave(ranges?.filter((range, _) => range.start !== '' && range.end !== ''));
        }
    };

    const handleDeleteRange = (index) => {
        setRanges(ranges.filter((_, i) => i !== index));
    };

    const addTimeRangeIsActive = () => {
        return ranges == null || ranges?.length < 4;
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h2>{getFullWeekdayName(dayOfWeek)}</h2>
                    <button onClick={onClose}>&times;</button>
                </div>
                <div className={styles.modalBody}>
                    {ranges?.map((range, index) => (

                        <div key={index} className={styles.rangeContainer}>
                            <div className={styles.timeContainer}>
                                <span className={styles.rangeText}>Начало</span>
                                <input className={styles.time}
                                       type="time"
                                       value={range.start}
                                       onChange={(e) => handleTimeChange(index, 'start', e.target.value)}
                                />
                            </div>
                            <div className={styles.timeContainer}>
                                <span className={styles.rangeText}>Окончание</span>
                                <input className={styles.time}
                                       type="time"
                                       value={range.end}
                                       onChange={(e) => handleTimeChange(index, 'end', e.target.value)}
                                />
                            </div>
                            <button className={styles.deleteButton} onClick={() => handleDeleteRange(index)}>Удалить
                            </button>
                        </div>
                    ))}
                    {addTimeRangeIsActive() ?
                        <button onClick={handleAddTimeRange}>Добавить часы работы</button>
                        : null
                    }
                </div>
                <button onClick={handleSaveChanges}>Сохранить</button>
            </div>
        </div>
    );
};

export default WorkTimeModal;