import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import pages from '../../pages';
import TrainerCard from "./TrainerCard";
import NavigationBar from "../common/NavigationBar";
import styles from "./Trainers.module.css";
import {findByCategory} from "../../services/clientService";
import {handleRestError} from "../../services/restUtils";


const Trainers = () => {

    const [filteredTrainers, setFilteredTrainers] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const category = query.get('category');

        findByCategory(category, 0, 100)
            .then(response => setFilteredTrainers(response.data))
            .catch(error => handleRestError(error, navigate));

    }, [navigate, location]);

    return (
        <div className={styles.trainersPageContainer}>
            <h2>Тренеры</h2>
            <div className={styles.trainerList}>
                {filteredTrainers.map(trainer => (
                    <Link to={`${pages.trainerView}?id=${trainer.id}`}>
                        <TrainerCard
                            key={trainer.id}
                            name={trainer.fullName}
                            photoBase64={trainer.photoBase64}
                            rating={trainer.rating}
                            location={trainer.address}
                            tags={trainer.subcategoryCodes}
                        />
                    </Link>
                ))}
            </div>
            <NavigationBar/>
        </div>
    );
};

export default Trainers;