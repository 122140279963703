export default {
    authentication: '/',
    chooseClientType: '/chooseClientType',
    registration: '/registration',

    home: '/home',

    trainers: '/trainers',
    trainerView: '/trainers/view',

    profile: '/profile/user',
    profileDetails: `/profile/user/details`,

    schedule: '/schedule',
    trainerWorkTime: '/schedule/workTime',

    notFound: '/error/404',
    forbidden: '/error/403',
    needReload: '/error/needReload',
    // ... другие страницы
};