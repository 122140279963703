import React, {useEffect, useState} from 'react';
import styles from './ProfileDetailsPage.module.css';
import NavigationBar from "../common/NavigationBar";
import {useNavigate} from "react-router-dom";
import {getProfile} from "../../services/clientService";
import {handleRestError} from "../../services/restUtils";

const ProfilePage = () => {

    const navigate = useNavigate();

    const [clientProfileDto, setClientProfileDto] = useState({});

    useEffect(() => {
        getProfile()
            .then(response => {
                setClientProfileDto(response.data);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });

    }, [navigate]);


    return (
        <div className={styles.MainContainer}>
            <header className={styles.Header}>
                <h1 className={styles.ProfileTitle}>Личные данные</h1>
            </header>



            <div className={styles.optionalBlocks}>
                {clientProfileDto ?
                    <div className={styles.expandedBlock}>
                        <section className={styles.expandedBlockSection}>
                            <div className={styles.whiteBoxContainer}>
                                <section className={styles.whiteBoxCard}>
                                    <span>Фамилия: {clientProfileDto?.lastName}</span>
                                    <span>Имя: {clientProfileDto?.firstName}</span>
                                    <span>Дата рождения: {clientProfileDto?.birthday}</span>
                                    <span>Пол: {clientProfileDto?.sex}</span>
                                </section>
                            </div>

                        </section>
                    </div>
                    : null
                }

            </div>

            <NavigationBar/>
        </div>
    );
};


export default ProfilePage;
