import React from 'react';
import styles from './TrainingCard.module.css';
import {format, parse} from "date-fns";
import {PATTERN_DATE, PATTERN_DATETIME, PATTERN_TIME} from "../../utils/DateUtil";

const TrainingCard = ({id, datetime, activityName, trainerName}) => {

    return (
        <article className={styles.trainingCard}>
            <div className={styles.dateTimeRow}>
                <span className={styles.trainingDate}>Дата: {format(datetime, PATTERN_DATE)}</span>
                <span className={styles.trainingTime}>Время: {format(datetime, PATTERN_TIME)}</span>
            </div>
            <span className={styles.trainerName}>Тренер: {trainerName}</span>
            <span className={styles.activityName}>{activityName}</span>
            {parse(datetime, PATTERN_DATETIME, new Date()) > new Date() ?
                <button className={styles.modifyTraining}>Отменить или перенести</button>
                : null
            }
        </article>
    );
};

export default TrainingCard;
