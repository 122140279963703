import {simpleApiClient} from "./apiClient";
import apiTelegram from "./apiTelegram";

export const login = async () => {
    const tgData = apiTelegram.initDataUnsafe;
    const response = await simpleApiClient.post(`/v1/auth/login`, tgData);

    if (response.status === 200 && response.data.token) {
        const {token, role} = response.data;
        localStorage.setItem('authToken', token);
        localStorage.setItem('userRole', role);
    }
};

export const currentRole = () => {
    return localStorage.getItem('userRole');
}

export const Roles = {
    TRAINER: 'TRAINER',
    CLIENT: 'CLIENT',
};