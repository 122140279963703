import React from 'react';
import styles from './CategoryCard.module.css'
import {useNavigate} from "react-router-dom";
import pages from "../../pages";


const CategoryCard = ({category}) => {

    const navigate = useNavigate();

    const goToTrainersCategory = (categoryCode) => {
        if (categoryCode) {
            navigate(`${pages.trainers}?category=${categoryCode}`);
        }
    }

    return (
        <div className={styles.cardContainer}
             style={{backgroundImage: `url(${category?.image})`}}
             onClick={() => goToTrainersCategory(category.code)}>

            <div className={styles.categoryContainer}>
                <span className={styles.categoryName}>{category?.name}</span>
            </div>
        </div>
    );
};

export default CategoryCard;