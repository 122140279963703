import React, {useEffect, useState} from "react";
import styles from "./ReviewContainer.module.css";
import {findComments} from "../../services/clientService";
import {handleRestError} from "../../services/restUtils";
import {useNavigate} from "react-router-dom";

const ReviewContainer = ({trainerId}) => {

    const navigate = useNavigate();
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (trainerId) {
            findComments(trainerId, 1, 3)
                .then(response => setComments(response.data))
                .catch(error => handleRestError(error, navigate));
        }

    }, [trainerId]);

    return (
        <section className={styles.container}>
            <section className={styles.reviewSection}>
                {comments?.map((review) => (
                    <article key={review.id} className={styles.reviewCard}>
                        <div className={styles.reviewer}>
                            <h3 className={styles.reviewerName}>{review.author}</h3>
                            <div className={styles.reviewRating}>
                                <span className={styles.rating}>{review.rating}</span>
                                <img
                                    loading="lazy"
                                    src={`/img/common/icons/star-black.svg`}
                                    alt="Rating star"
                                    className={styles.ratingImage}
                                />
                            </div>
                        </div>
                        <p className={styles.comment}>{review.comment}</p>
                    </article>
                ))}
            </section>
        </section>
    );
};

export default ReviewContainer;