import {retryableApiClient} from "./apiClient";

export const saveSpecialization = async (sportSubCategories) => {
    return retryableApiClient.post(
        `/v1/trainer/specialization`,
        sportSubCategories
    );
};

export const saveAbout = async (about, city, address) => {
    return retryableApiClient.post(
        `/v1/trainer/about`,
        {
            about: about,
            city: city,
            address: address,
        }
    );
};

export const getWorkTime = async () => {
    return retryableApiClient.get(`/v1/trainer/work-time`);
}

export const updateWorkTime = async (workTime) => {
    return retryableApiClient.post(
        `/v1/trainer/work-time`,
        JSON.stringify(workTime)
    );
}