const mockTelegramWebApp = {
    version: 'mock_version',
    initDataUnsafe: {
        "query_id": "SOME_QUERY_ID",
        "user": {
            "id": 1100110011,
            "first_name": "Useracli",
            "last_name": "Testov",
            "username": "test_username",
            "language_code": "en",
            "allows_write_to_pm": true
        },
        "auth_date": "1728149465",
        "hash": "db24cb058101293d3a53d6f0114c533f43b81e2871e32db79b52cd3e80f8e202",
    },
    viewportStableHeight: '100vh',
    viewportHeight: '100vh',
    onEvent: (event, callback) => {
        console.log(`Mock event handler: event=${event}`);
    },
    offEvent: (event, callback) => {
        console.log(`Mock offEvent handler: event=${event}`);
    },
    close: () => {
        console.log('Mock close');
    },
    expand: () => {
        console.log('Mock expand');
    },
    ready: () => {
        console.log('Mock ready');
    }
    // Добавьте любые другие методы, которые вам нужны
};


const apiTelegram = (() => {
    if ('local' === process.env.REACT_APP_MODE) {
        return mockTelegramWebApp;
    } else {
        if (window.Telegram && window.Telegram.WebApp) {
            console.log('---------------');
            console.log(window.Telegram.WebApp);
            console.log('---------------');
            return window.Telegram.WebApp;
        } else {
            console.warn('Telegram WebApp is not available.');
            return null;
        }
    }
})();

export default apiTelegram;
