import React from 'react';
import styles from './WideButton.module.css'

const WideButton = ({text, children, onClick, transparent=false}) => {

    const buttonClass = transparent ? styles.transparent : styles.wideButton;

    return (
        <button className={`${styles.common} ${buttonClass}`} onClick={onClick}>
            {text}
            {children}
        </button>
    )
}

export default WideButton;