import React from "react";
import styles from "./FavoriteTrainers.module.css";
import ScrollList from "../common/ScrollList";
import {Link} from "react-router-dom";
import {getPhoto} from "../../utils/PhotoUtil";
import {joinStrings} from "../../utils/StringUtil";
import pages from "../../pages";


const FavoriteTrainers = ({trainersData}) => {

    if (trainersData) {
        return (
            <div className={styles.container}>
                <section className={styles.trainersContainer}>
                    <ScrollList items={trainersData.map((trainer) => (
                        <Link to={`${pages.trainerView}?id=${trainer.id}`}>
                            <TrainerCard key={trainer.id}
                                         name={joinStrings(' ', trainer.firstName, trainer.lastName)}
                                         photoBase64={getPhoto(trainer.photoBase64)}/>
                        </Link>

                    ))}/>
                </section>
            </div>
        );
    } else {
        return null;
    }

}

const TrainerCard = ({name, photoBase64}) => (
    <div className={styles.trainerCardContainer}>
        <img
            className={styles.trainerImage}
            loading="lazy"
            src={photoBase64}
            alt={`Portrait of ${name}`}
        />
        <h3 className={styles.trainerName}>{name}</h3>
    </div>
);

export default FavoriteTrainers;