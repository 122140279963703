import React, {useEffect, useState} from 'react';
import NavigationBar from "../common/NavigationBar";
import styles from "./SchedulePage.module.css";
import Calendar from "../trainer/Calendar";
import TrainingCard from "../common/TrainingCard";
import {useNavigate} from "react-router-dom";
import pages from "../../pages";

import {format, parse} from 'date-fns';
import {PATTERN_DATE, PATTERN_DATETIME, VISIBLE_DATE_FORMAT} from "../../utils/DateUtil";
import WideButton from "../common/WideButton";
import {findCalendarEvents} from "../../services/userService";
import {handleRestError} from "../../services/restUtils";
import {currentRole, Roles} from "../../services/authService";

const SchedulePage = () => {

    const navigate = useNavigate();

    const [bookings, setBookings] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [highlightDates, setHighlightDates] = useState([]);

    useEffect(() => {
        refreshBookings(selectedDate ? selectedDate : new Date());
    }, []);

    useEffect(() => {
        setHighlightDates(bookings.map(booking => format(booking.start, PATTERN_DATE)));
    }, [bookings]);

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleChangeMonth = (newDate) => {
        refreshBookings(newDate ? newDate : new Date());
    };

    const handleReservationButton = () => {
        navigate(pages.trainerWorkTime);
    }

    function refreshBookings(date) {
        findCalendarEvents(date.getFullYear(), date.getMonth() + 1)
            .then(response => {
                setBookings(response.data);
            })
            .catch(error => handleRestError(error, navigate));
    }

    function getBookingTitle() {
        if (selectedDate.toDateString() === new Date().toDateString()) {
            return "Записи на сегодня";
        } else {
            return `Записи на ${format(selectedDate, VISIBLE_DATE_FORMAT)}`;
        }
    }

    function getTrainingCard(booking) {
        return <div className={styles.trainingItem}>
            <TrainingCard id={booking.id}
                          datetime={booking.start}
                          trainerName={booking.trainerName}
                          activityName={booking.activityName}
            />
        </div>;
    }

    return (
        <div className={styles.trainersSchedulePageContainer}>
            <h2 className={styles.title}>Мое расписание</h2>
            <Calendar eventDates={highlightDates}
                      onChangeHighlightedDate={handleDateChange}
                      onChangeMonth={handleChangeMonth}
            />
            {currentRole() === Roles.TRAINER ?
                <div className={styles.workTimeButton}>
                    <WideButton text="Часы работы" transparent={false} onClick={handleReservationButton}/>
                </div>
                : null
            }
            <section className={styles.trainingSection}>
                <h2 className={styles.trainingTitle}>{getBookingTitle()}</h2>
                {bookings
                    .filter(booking => parse(booking.start, PATTERN_DATETIME, new Date()).toDateString() === selectedDate.toDateString())
                    .map(booking => getTrainingCard(booking))}
            </section>
            <NavigationBar className={styles.navBar}/>
        </div>
    );
};

export default SchedulePage;