export const PATTERN_DATETIME = 'yyyy-MM-dd HH:mm:ss';
export const PATTERN_DATE = 'yyyy-MM-dd';
export const PATTERN_TIME = 'HH:mm';

export const VISIBLE_DATE_FORMAT = 'dd.MM.yyyy';

const weekdays = {
    MON: "Понедельник",
    TUE: "Вторник",
    WED: "Среда",
    THU: "Четверг",
    FRI: "Пятница",
    SAT: "Суббота",
    SUN: "Воскресенье",
};

export function getShortWeekDays() {
    return Object.keys(weekdays)
}

export function getFullWeekdayName(shortName) {
    if (shortName == null) {
        return null;
    }
    return weekdays[shortName.toUpperCase()] || null;
}