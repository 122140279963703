import React, {useEffect, useState} from 'react';
import TextInput from "../common/TextInput";
import DateInput from "../common/DateInput";
import pages from '../../pages';

import {useLocation, useNavigate} from "react-router-dom";
import WideButton from "../common/WideButton";
import styles from "./RegistrationForm.module.css";
import {registration} from "../../services/userService";
import apiTelegram from "../../services/apiTelegram";
import {handleRestError} from "../../services/restUtils";
import * as authService from "../../services/authService";
import ProfilePicture from "../common/ProfilePicture";
import {convertToBase64} from "../../utils/FileUtil";
import {getPhoto} from "../../utils/PhotoUtil";


const RegistrationForm = () => {

    let navigate = useNavigate();
    const location = useLocation();

    const female = 'F';
    const male = 'M';

    const [gender, setGender] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [userType, setUserType] = useState('');
    const [photoBase64, setPhotoBase64] = useState(null);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        setUserType(query.get('category'));

    }, [location]);

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };


    const handlePhotoChange = (event) => {
        convertToBase64(event, setPhotoBase64);
    };

    const handleRegistration = async () => {
        const telegramUserId = apiTelegram.initDataUnsafe?.user?.id;

        let questionary = {
            telegramId: telegramUserId,
            photoBase64: photoBase64,
            firstName: name,
            lastName: surname,
            sex: gender,
            birthday: birthdate,
        }
        registration(userType, questionary)
            .then(response => {
                if (response.status === 200) {
                    authService.login()
                        .then(() => {
                            navigate(pages.home);
                        })
                        .catch(error => {
                            handleRestError(error, navigate);
                        });
                } else {
                    console.log("Ошибка... =(");
                }
            })
            .catch(error => {
                handleRestError(error, navigate);
            });

    };

    return (

        <div className={styles.registrationPage}>
            <form className={styles.styledForm}>
                <div className={styles.photoSection}>
                    <ProfilePicture src={getPhoto(photoBase64)} onChange={handlePhotoChange}/>
                    <p className={styles.photoInstruction}>Выбрать фото профиля (не обязательно)</p>
                </div>
                <div className={styles.textInputs}>
                    <TextInput id="nameInput"
                               label="Имя"
                               placeholder="Введите имя"
                               value={name}
                               onChange={e => setName(e.target.value)}
                    />
                    <TextInput id="surnameInput"
                               label="Фамилия"
                               placeholder="Введите фамилию"
                               value={surname}
                               onChange={e => setSurname(e.target.value)}
                    />
                </div>
                <div className={styles.genderDateContainer}>
                    <div className={styles.genderContainer}>
                        <h3 className={styles.genderTitle}>Пол</h3>
                        <div className={styles.genders}>
                            <div className={styles.genderOption}>
                                <label className={styles.genderLabel}>М</label>
                                <input
                                    type="radio"
                                    name="gender"
                                    value={male}
                                    checked={gender === male}
                                    onChange={handleGenderChange}
                                    className={styles.genderRadio}
                                />
                            </div>
                            <div className={styles.genderOption}>
                                <label className={styles.genderLabel}>Ж</label>
                                <input
                                    type="radio"
                                    name="gender"
                                    value={female}
                                    checked={gender === female}
                                    onChange={handleGenderChange}
                                    className={styles.genderRadio}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.dateContainer}>
                        <DateInput label="Дата рождения" value={birthdate}
                                   onChange={e => setBirthdate(e.target.value)}/>
                    </div>
                </div>
            </form>
            <div className={styles.registrationButton}>
                <WideButton text="Зарегистрироваться" onClick={handleRegistration}/>
            </div>
        </div>

    );
}

export default RegistrationForm;