import React, {useState} from 'react';
import styles from './SearchBar.module.css'

const SearchBar = ({placeholder}) => {
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Do something with the value, such as sending a request to an API
        console.log('Попытка найти: ' + value);
    };

    return (
        <form className={styles.searchBar} onSubmit={handleSubmit}>
            <input
                className={styles.searchInput}
                type="text"
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
            />
            <button className={styles.searchButton} type="submit">Поиск</button>
        </form>
    );
};

export default SearchBar;