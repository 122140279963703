import React from 'react';
import SearchBar from './SearchBar';
import CategoryCard from './CategoryCard';
import styles from './Home.module.css'
import NavigationBar from "../common/NavigationBar";

const categories = [
    {name: 'Фитнес', code: 'FITNESS', image: 'img/home/categories/fitness.png'},
    {name: 'Йога', code: 'JOGA', image: 'img/home/categories/joga.png'},
    {name: 'Кроссфит', code: 'CROSSFIT', image: 'img/home/categories/crossfit.png'},
    {name: 'Танцевальные', code: 'DANCE', image: 'img/home/categories/dances.png'},
    {name: 'Командные', code: 'GROUP', image: 'img/home/categories/command.png'},
    {name: 'Для дома', code: 'AT_HOME', image: 'img/home/categories/home.png'},
    {name: 'Экстремальные', code: 'EXTREME', image: 'img/home/categories/extreme.png'},
    {name: 'Единоборства', code: 'FIGHTS', image: 'img/home/categories/fights.png'},
];


const Home = () => {

    return (
        <div className={styles.home}>
            <div className={styles.content}>
                <div className={styles.searchBar}>
                    <SearchBar placeholder="Найти тренера по имени"/>
                </div>
                <div className={styles.blockName}>
                    <h2 className={styles.blockNameText}>Выбор по виду спорта</h2>
                </div>
                {/*<h2>Выбор по виду спорта</h2>*/}
                <div className={styles.categories}>
                    <div className={styles.categoryRow}>
                        <CategoryCard category={categories[0]}/>
                        <CategoryCard category={categories[1]}/>
                    </div>
                    <div className={styles.categoryRow}>
                        <CategoryCard category={categories[2]}/>
                        <CategoryCard category={categories[3]}/>
                    </div>
                    <div className={styles.categoryRow}>
                        <CategoryCard category={categories[4]}/>
                        <CategoryCard category={categories[5]}/>
                    </div>
                    <div className={styles.categoryRow}>
                        <CategoryCard category={categories[6]}/>
                        <CategoryCard category={categories[7]}/>
                    </div>
                </div>
            </div>
            <NavigationBar/>
        </div>
    );
}

export default Home;