import React, {useEffect, useState} from "react";
import styles from "./TrainerWorkTimePage.module.css";
import {useNavigate} from "react-router-dom";
import {currentRole, Roles} from "../../services/authService";
import pages from "../../pages";
import {handleRestError} from "../../services/restUtils";
import {getWorkTime, updateWorkTime} from "../../services/trainerService";
import NavigationBar from "../common/NavigationBar";
import WorkTimeModal from "./component/WorkTimeModal";
import {getFullWeekdayName, getShortWeekDays} from "../../utils/DateUtil";

function TrainerWorkTimePage() {

    const navigate = useNavigate();

    const [workTime, setWorkTime] = useState(null);

    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedRanges, setSelectedRanges] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (currentRole() !== Roles.TRAINER) {
            navigate(pages.home);
        }
        getWorkTime()
            .then(response => {
                setWorkTime(response.data);
            })
            .catch(error => handleRestError(error, navigate));
    }, [navigate]);


    const toggleDayActive = (day, isActive) => {
        const updatedWorkTime = {...workTime,
            [day]: {...workTime[day], isActive: isActive}
        };

        updateWorkTime(updatedWorkTime)
            .then(_ => {
                setWorkTime(updatedWorkTime);
            })
            .catch(error => handleRestError(error, navigate));
    }

    const selectDayHandler = (day, isSelected) => {
        const timeRanges = workTime[day]?.timeRanges;
        console.log('day, timeRanges');
        console.log(day, timeRanges);

        setSelectedDay(day);
        setIsModalOpen(isSelected);
        setSelectedRanges(timeRanges);
    }

    const updateTimeRangesInDay = (updatedRanges) => {
        const updatedWorkTime = {...workTime,
            [selectedDay]: {...workTime[selectedDay], timeRanges: updatedRanges, isActive: updatedRanges?.length > 0}
        };

        updateWorkTime(updatedWorkTime)
            .then(_ => {
                setWorkTime(updatedWorkTime);
                setIsModalOpen(false);
            })
            .catch(error => handleRestError(error, navigate));
    }

    return (
        <div className={styles.workTimePageContainer}>
            {workTime &&
                getShortWeekDays().map((day, idx) => {
                    const timeRanges = workTime[day]?.timeRanges;

                    return <DayContainer key={idx} day={day} timeRanges={timeRanges}
                                         isActive={workTime[day]?.isActive}
                                         onToggle={toggleDayActive}
                                         onOpenModal={() => selectDayHandler(day, true)}/>
                })
            }
            <WorkTimeModal
                dayOfWeek={selectedDay}
                timeRanges={selectedRanges}
                isOpen={isModalOpen}
                onClose={() => selectDayHandler(null, false)}
                onSave={updateTimeRangesInDay}
            />
            <NavigationBar className={styles.navBar}/>
        </div>
    );
}


function DayContainer({day, timeRanges, isActive, onToggle, onOpenModal}) {

    const [checked, setChecked] = useState(isActive);

    useEffect(() => {
        if (!(timeRanges?.length > 0) || !isActive) {
            setChecked(false);
        } else if (isActive) {
            setChecked(true);
        }
    }, [timeRanges, checked, isActive]);

    const handleCheckedChange = (event) => {
        const value = event.target.checked;

        if (timeRanges?.length > 0) {
            setChecked(value);
            onToggle(day, value);
        }
    };

    return (
        <div className={styles.dayContainer} onClick={onOpenModal}>
            <div className={styles.dayLeftPart}>
                <span className={styles.dayName}>{getFullWeekdayName(day)}</span>
                <div className={styles.timeRanges}>
                    <div className={styles.timeRange}>
                        {(timeRanges != null && timeRanges?.length > 0)
                            ? timeRanges.map(range => `${range.start} - ${range.end}`).join(', ')
                            : "Закрыто"
                        }
                    </div>
                </div>
            </div>
            <div className={styles.dayRightPart} onClick={(e) => e.stopPropagation()}>
                <input className={styles.toggle}
                       type="checkbox"
                       checked={checked}
                       onChange={handleCheckedChange}
                />
            </div>
        </div>
    );
}


export default TrainerWorkTimePage;