import React from "react";
import styles from './TrainerCard.module.css';

const TrainerCard = ({name, photoBase64, rating, location, tags = []}) => {

    function getShortTags(tags) {
        const tagsViewLimit = 2;
        if (tags && tags.length > tagsViewLimit) {
            var shortTags = tags.slice(0, tagsViewLimit);
            let child = tags.length - tagsViewLimit;
            if (child > 0) {
                shortTags.push('+ ' + child)
            }
            return shortTags;
        }
        return tags;
    }

    return (
        <div className={styles.Card}>
            <img src={`data:image/jpeg;base64,${photoBase64}`} className={styles.Avatar} alt="avatar"/>
            <div className={styles.UserInfo}>
                <div className={styles.NameAndRating}>
                    <h2 className={styles.Name}>{name}</h2>
                    <div className={styles.Rating}>
                        <span className={styles.Stars}>{rating}</span>
                        <img className={styles.StarIcon} src="img/common/icons/star-black.svg" alt="star"/>
                    </div>
                </div>
                <address className={styles.Address}>{location}</address>
                <div className={styles.Activities}>
                    {getShortTags(tags).map((activity, index) => (
                        <div className={styles.Activity}> {activity}</div>
                    ))}
                </div>
            </div>
        </div>);
};
export default TrainerCard;