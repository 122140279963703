import pages from "../pages";

function handleRestError(error, navigate) {
    let targetPage;
    let isNeedRemoveToken;
    if (error.response) {
        const status = error.response.status;

        if (status === 404) { // пользователь не найден, отправляем на регистрацию
            targetPage = pages.chooseClientType;
            isNeedRemoveToken = true;

        } else if (status === 403) { // пользователю доступ запрещен
            targetPage = pages.forbidden;
            isNeedRemoveToken = true;

        } else if (status === 400) {
            console.error('Bad user request: ' + status, error);
        } else {
            console.error('Strange code: ' + status, error);
            targetPage = pages.authentication;
            isNeedRemoveToken = true;
        }
    } else {
        console.error('Login failed', error);
        targetPage = pages.authentication;
    }

    if (isNeedRemoveToken) {
        localStorage.removeItem('authToken');
    }

    if (targetPage && targetPage !== pages.authentication) {
        navigate(targetPage);
    }
}

export {handleRestError};