import YouTubeVideo from "../common/YouTubeVideo";
import React, {useEffect} from "react";
import styles from "./ForbiddenPage.css";
import * as authService from "../../services/authService";
import pages from "../../pages";
import {handleRestError} from "../../services/restUtils";
import {useNavigate} from "react-router-dom";

const ForbiddenPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        authService.login()
            .then(() => {
                navigate(pages.home);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });

    }, []);

    return (
        <div className={styles.forbiddenPage}>
            <h1>Сорян, но сюда нельзя</h1>
            <YouTubeVideo videoId="1Qo35ldlvWo"/>
        </div>
    );
}

export default ForbiddenPage;