import React from "react";
import styles from "./DateInput.module.css";


const DateInput = ({label, value, onChange}) => {

    return (
        <div className={styles.dateInputContainer}>
            <h3 className={styles.label}>{label}</h3>
            <div className={styles.inputContainer}>
                <input
                    type="date"
                    className={styles.input}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

export default DateInput;